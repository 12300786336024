import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
	APPLICATION,
	GenericLineItem,
	genericLineItemHasId,
	GenericLineItemWithBasePrice,
	GenericLineItemWithId,
	InventoryPart,
	LineItemType,
	lineTypesCannotOverridePrice,
	lineTypesFreightish,
	lineTypesOneOnly,
	lineTypesRequireDescription,
	lineTypesRequireUserPrice,
	makeApiErrorMessage,
	PartInBin,
	PriceOverrideReason,
	PriceOverrideReasonTypeId,
	useAuth,
	useLinePricingPost,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber, unpythonify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	Checkbox,
	Divider,
	EmptyValueDash,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	Information,
	Label,
	LabeledData,
	Modal,
	ModalProps,
	NumericInput,
	ORDER_WEIGHT_LIMIT,
	Paragraph,
	PartSelector,
	Price,
	TextInput,
	useChangeCallback,
} from "@ncs/web-legos"

import {
	LineItemTypeSelector,
	PartBinSelector,
	PriceOverrideReasonSelector,
} from "../../selectors"
import { css } from "@emotion/react"

export interface SaveLineItemExtras {
	/**
	 * Is this a brand new line item or not?
	 */
	isEdit: boolean
	/**
	 * The user wants to override the price but does not have the required permission.
	 */
	isOverrideRequest: boolean
	/**
	 * Dispatch: Selected bin to take part from.
	 */
	binId: string | null
	/**
	 * If the modal never was able to determine a reliable base price, then that could be useful
	 * information. We put it here because the onSave function needs to (investigate this later?)
	 * fallback to that being an actual value.
	 */
	basePriceWasNull: boolean
}

export interface LineItemEditorModalProps extends ExtendableModalProps {
	shipToId: string | null
	billToId: string | null
	onSave: (
		newLineItem: GenericLineItemWithBasePrice,
		extras: SaveLineItemExtras
	) => void | Promise<void>
	onRemove: (lineToDelete: GenericLineItemWithId) => void | Promise<void>
	lineItemToEdit?: GenericLineItem | null
	disableQuantityEdit?: boolean
	isPurchaseOrder?: boolean
	isDispatch?: boolean
	currentWeightTotal?: number
	weightNotToExceed?: number
	hideShippingExtras?: boolean
	/** Unless this prop is true, if you supply a `shipToId` or `billToId` then we'll pass that in
	 * as the `restrictionsCustomer` prop into PartSelector. */
	skipRestrictedCheck?: boolean
	/** Don't send back any restricted parts at all from the PartSelector. */
	excludeRestrictedParts?: boolean
}

/** Default to line item type of Parts. */
const initialType = LineItemType.Parts

export const LineItemEditorModal: FC<LineItemEditorModalProps> = memo(
	({
		lineItemToEdit,
		shipToId,
		billToId,
		onSave,
		onRemove,
		onClose,
		disableQuantityEdit: disableQuantityEditProp,
		isPurchaseOrder = false,
		isDispatch = false,
		currentWeightTotal = 0,
		weightNotToExceed = ORDER_WEIGHT_LIMIT,
		hideShippingExtras,
		skipRestrictedCheck,
		excludeRestrictedParts,
		...rest
	}) => {
		const { user } = useAuth()
		const userApps: (APPLICATION | null)[] = useMemo(() => user?.apps ?? [], [user?.apps])
		const hasSubmitted = useRef(false)
		const linePricingPost = useLinePricingPost()

		const [typeId, setTypeId] = useState<LineItemType | null>(initialType)
		const [selectedPart, setSelectedPart] = useState<InventoryPart | null>(null)
		const [selectedBin, setSelectedBin] = useState<PartInBin | null>(null)
		const [description, setDescription] = useState<string | null>(null)
		const [quantity, setQuantity] = useState<number | null>(1)
		const [isLoadingPricing, setIsLoadingPrice] = useState(false)
		const [isSaving, setIsSaving] = useState(false)
		const [discountPercent, setDiscountPercent] = useState<number | null>(0)
		const [isRemoving, setIsRemoving] = useState(false)
		const [isOverridingPrice, setIsOverridingPrice] = useState(false)
		const [priceOverrideReason, setPriceOverrideReason] = useState<PriceOverrideReason | null>(
			null
		)
		const [priceOverrideOtherReason, setPriceOverrideOtherReason] = useState<string | null>(
			null
		)
		const [isBillable, setIsBillable] = useState(() => {
			return !!isDispatch && !lineItemToEdit
		})
		const [isUnderWarranty, setIsUnderWarranty] = useState(false)
		const [isConfirmingRemove, setIsConfirmingRemove] = useState(false)
		const [errorText, setErrorText] = useState<string | null>(null)
		const partSelectorRef = useRef<HTMLDivElement | null>(null)

		const notInNetsuit = selectedPart?.netsuiteId === null
		const isQAOrDevelopment =
			process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development"

		const [pricing, setPricing] = useState<PricingState>({
			finalPriceToUse: null,
			finalPriceOriginal: null,
			basePrice: null,
			taxRate: null,
		})
		// When useContract is null, that'll mean the item is not covered. When it's a boolean
		// that means it is covered and we can toggle whether or not it's used for the line.
		const [useContract, setUseContract] = useState<boolean | null>(null)

		// Set fields' initial values when passing in an item to edit. Note that this is what sets
		// up our state initially when opening the modal to edit a line item.
		useChangeCallback(
			lineItemToEdit,
			(newLineItemToEdit) => {
				if (newLineItemToEdit) {
					setTypeId(newLineItemToEdit.lineTypeId)
					setDescription(
						newLineItemToEdit.part?.description ?? newLineItemToEdit.description ?? ""
					)
					setQuantity(newLineItemToEdit.quantity)
					setIsOverridingPrice(!!newLineItemToEdit.priceOverrideReason)
					setPriceOverrideReason(newLineItemToEdit.priceOverrideReason)
					setPriceOverrideOtherReason(newLineItemToEdit.reasonComment)
					if (isDispatch) {
						setIsBillable(newLineItemToEdit.isBillable ?? true)
						setIsUnderWarranty(newLineItemToEdit.isUnderWarranty ?? false)
					}

					// Call the line pricing endpoint.
					void getAndSetLinePricing(
						newLineItemToEdit.lineTypeId,
						newLineItemToEdit.part?.id
					)
				}
			},
			{ callOnSetup: true }
		)

		// When the line type or the part changes, we need to call the backend to get the
		// pricing on the new stuff.
		const getAndSetLinePricing = async (
			lineTypeId: LineItemType,
			partId: string | null | undefined,
			{
				removePriceEdit,
			}: {
				removePriceEdit?: boolean
			} = {
				removePriceEdit: false,
			}
		) => {
			try {
				if (!shipToId) throw new Error("getLinePricing is being called without a shipToId")

				setIsLoadingPrice(true)

				const response = await linePricingPost({
					customerId: shipToId,
					billToId,
					lines: [
						{
							lineTypeId,
							partId: partId ?? null,
						},
					],
				})

				const linePricing = unpythonify(response.data.lines[0])

				if (lineItemToEdit) {
					// If it was a system generated line, then we don't care what was returned from the
					// line pricing call.
					if (lineItemToEdit.systemGeneratedLine) {
						setPricing({
							finalPriceOriginal: lineItemToEdit.originalSystemGeneratedPrice,
							basePrice: lineItemToEdit.originalSystemGeneratedPrice,
							finalPriceToUse:
								removePriceEdit ?
									lineItemToEdit.originalSystemGeneratedPrice
								:	lineItemToEdit.requestedPrice ?? lineItemToEdit.finalPrice,
							taxRate: lineItemToEdit.taxRate,
						})
					} else if (lineItemToEdit.lineTypeId === LineItemType.Freight) {
						// The pricing call for freight will just always return 0, which is not useful.
						setPricing({
							finalPriceOriginal: lineItemToEdit.finalPrice,
							basePrice: lineItemToEdit.basePrice,
							finalPriceToUse:
								removePriceEdit ?
									lineItemToEdit.finalPrice
								:	lineItemToEdit.requestedPrice ?? lineItemToEdit.finalPrice,
							taxRate: lineItemToEdit.taxRate,
						})
					} else {
						// Use the results from the line pricing call.
						setPricing({
							finalPriceOriginal: lineItemToEdit.finalPrice ?? linePricing.netPrice,
							basePrice: linePricing.unitPrice,
							finalPriceToUse:
								removePriceEdit ?
									linePricing.contractCovered ?
										0
									:	linePricing.netPrice
								:	lineItemToEdit.requestedPrice ?? lineItemToEdit.finalPrice,
							taxRate: lineItemToEdit.taxRate,
						})
					}
					setUseContract(
						linePricing.contractCovered ? lineItemToEdit.finalPrice === 0 : null
					)
				} else if (linePricing.contractCovered) {
					// If item is contract covered, by default we'll use contract and set price to 0.
					// (Still set the tax rate though, and keep the actual price in case user toggles off contract)
					setUseContract(true)
					setPricing({
						taxRate: linePricing.taxRate / 100, // Server sends rate back as percentage, convert to decimal.
						finalPriceToUse: 0,
						finalPriceOriginal: linePricing.netPrice,
						basePrice: linePricing.unitPrice,
					})
				} else {
					setUseContract(null)
					setPricing({
						taxRate: linePricing.taxRate / 100,
						finalPriceToUse: linePricing.netPrice,
						finalPriceOriginal: linePricing.netPrice,
						basePrice: linePricing.unitPrice,
					})
				}
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
			} finally {
				setIsLoadingPrice(false)
			}
		}

		const handleTypeChange = (newType: LineItemType | null, newDescription: string) => {
			setTypeId(newType)
			setSelectedPart(null)
			setSelectedBin(null)
			setQuantity(1)

			if (newType) {
				if (newType !== LineItemType.Parts) {
					// If it's not a part, the description will be the line type's description.
					setDescription(newDescription)

					if (!lineTypesRequireUserPrice.includes(newType)) {
						setIsOverridingPrice(false)
						setPriceOverrideReason(null)
						void getAndSetLinePricing(newType, null)
					} else {
						clearPricing()
						if (lineTypesRequireDescription.includes(newType)) {
							setDescription(null)
						}
					}
				} else {
					// If it's a part, clear these out because they get filled out at part selection.
					setDescription(null)
					clearPricing()
				}
			} else {
				// If there's no new type, clear these out.
				setDescription(null)
				clearPricing()
			}
		}

		const handlePartChange = (newPart: InventoryPart | null) => {
			setSelectedPart(newPart)
			setSelectedBin(null)
			setQuantity(1)

			if (newPart && typeId) {
				void getAndSetLinePricing(typeId, newPart.id)
				setDescription(`(${newPart.partNumber}) ${newPart.description}`)
			} else {
				setDescription(null)
				clearPricing()
			}
		}

		const handleUseContractToggle = (newState: boolean) => {
			setUseContract(newState)
			if (newState) {
				setPricing(
					(prev): PricingState => ({
						...prev,
						finalPriceToUse: 0,
					})
				)
			} else {
				setPricing(
					(prev): PricingState => ({
						...prev,
						finalPriceToUse: prev.finalPriceOriginal,
					})
				)
			}
		}

		const handleSetPriceOverrideReason = (reason: PriceOverrideReason | null) => {
			setPriceOverrideReason(reason)
			setPricing(
				(prev): PricingState => ({
					...prev,
					// If the reason in billable, then we expect them to enter an actual price. But we'll
					// set the field to null to force them to actually think about it. If the reason is
					// NOT billable, then just set to zero.
					finalPriceToUse: reason?.isBillable ? null : 0,
				})
			)
		}

		const handleRemovePriceOverride = () => {
			if (!typeId) {
				throw new Error("trying to remove price override but there is not line item type")
			}

			// Reset price override stuff.
			setIsOverridingPrice(false)
			setPriceOverrideReason(null)
			setPriceOverrideOtherReason(null)

			void getAndSetLinePricing(typeId, selectedPart?.id ?? lineItemToEdit?.part?.id, {
				removePriceEdit: true,
			})
		}

		const handleSave = async (stayOpen?: boolean) => {
			if (quantity !== null ? quantity <= 0 : false) {
				setErrorText(
					"Quantity must be greater than zero. If you want to remove the line item, click 'Remove'."
				)
				return
			}
			if (pricing.finalPriceToUse == null) {
				setErrorText("Please enter a net price")
				return
			}
			if (priceOverrideReason?.textField && !priceOverrideOtherReason) {
				setErrorText("Please enter a reason for the price override.")
				return
			}
			if (isOverridingPrice && pricing.finalPriceOriginal === pricing.finalPriceToUse) {
				setErrorText("Edited price must be different")
				return
			}
			if (isDispatch && isBillable && !pricing.finalPriceToUse) {
				setErrorText("If line item is billable then a net price above $0 is required")
				return
			}
			if (isEditing === false && isDispatch && typeId === LineItemType.Parts) {
				if (!selectedBin) {
					setErrorText("Please select a bin")
					return
				}
				if (selectedBin.qty < (quantity ?? 0)) {
					setErrorText("Bin does not have sufficient quantity")
					return
				}
			}

			if (!hasSubmitted.current && typeId && quantity !== null) {
				try {
					hasSubmitted.current = true
					setIsSaving(true)

					let finalPrice: number | null = pricing.finalPriceToUse
					let requestedPrice: number | null = null

					// If user is overriding the price...
					if (isOverridingPrice) {
						// Set the requested price to the price to use.
						requestedPrice = pricing.finalPriceToUse
						// If the user requires approval, then set the finalPrice back to the original.
						if (overrideRequiresRequest) {
							finalPrice = pricing.finalPriceOriginal
						}
					}

					// Non-billable lines always have a price of 0.
					if (isDispatch && isBillable === false) {
						finalPrice = 0
					}

					if (finalPrice == null) {
						throw new Error(
							"Unable to determine net price for line item. Should not happen."
						)
					}

					const newLineItem: GenericLineItemWithBasePrice = {
						id: lineItemToEdit?.id,
						lineTypeId: typeId,

						// (Part and description won't change if you're editing)
						part: lineItemToEdit?.part ?? selectedPart,
						description: lineItemToEdit?.description ?? description ?? "",
						binId: isDispatch ? selectedBin?.binId.toString() ?? null : null,

						quantity,
						finalPrice,
						requestedPrice: requestedPrice,
						basePrice: pricing.basePrice ?? 0,
						subtotal: finalPrice * quantity,
						taxRate: pricing.taxRate ?? 0,
						priceOverrideReason: isOverridingPrice ? priceOverrideReason : null,
						reasonComment: isOverridingPrice ? priceOverrideOtherReason : null,
						overridePermission: lineItemToEdit?.overridePermission ?? null,
						systemGeneratedLine: lineItemToEdit?.systemGeneratedLine ?? false,
						originalSystemGeneratedPrice:
							lineItemToEdit?.originalSystemGeneratedPrice ?? null,
						isUnderWarranty: isDispatch ? isUnderWarranty : undefined,
						isBillable: isDispatch ? isBillable : undefined,
					}

					const extras: SaveLineItemExtras = {
						isEdit: isEditing,
						isOverrideRequest:
							!!lineItemToEdit?.overridePermission &&
							isOverridingPrice &&
							overrideRequiresRequest,
						binId: isDispatch ? selectedBin?.binId.toString() ?? null : null,
						basePriceWasNull: pricing.basePrice === null,
					}

					await onSave(newLineItem, extras)
					if (stayOpen) {
						setIsSaving(false)
						reset()
						if (partSelectorRef.current) partSelectorRef.current.focus()
					} else {
						onClose()
					}
				} catch (e) {
					setIsSaving(false)
					setErrorText(makeApiErrorMessage(e))
					hasSubmitted.current = false
				}
			}
		}

		const handleRemove = useCallback(async () => {
			try {
				if (!lineItemToEdit) {
					throw new Error("handleRemove being called without `lineItemToEdit`")
				}
				if (!genericLineItemHasId(lineItemToEdit)) {
					throw new Error("Unable to get ID of the line item being edited")
				}
				setIsRemoving(true)
				await onRemove(lineItemToEdit)
			} finally {
				setIsRemoving(false)
				onClose()
			}
		}, [lineItemToEdit, onClose, onRemove])

		const onDiscountBlur = (newDiscount: string) => {
			setPricing((prev) => ({
				...prev,
				finalPriceToUse:
					(prev.basePrice ?? 0) * ((100 - extractNumber(newDiscount)) / 100),
			}))
		}

		const clearPricing = () => {
			setPricing({
				basePrice: null,
				finalPriceToUse: null,
				finalPriceOriginal: null,
				taxRate: null,
			})
			setDiscountPercent(null)
			setUseContract(null)
			setIsOverridingPrice(false)
			setPriceOverrideReason(null)
			setPriceOverrideOtherReason(null)
		}

		const reset = () => {
			setIsLoadingPrice(false)
			setIsSaving(false)
			setIsRemoving(false)
			setIsConfirmingRemove(false)
			setErrorText(null)
			setTypeId(initialType)
			setSelectedPart(null)
			setDescription(null)
			setQuantity(1)
			clearPricing()
			hasSubmitted.current = false
		}

		// Whenever the price changes...
		useChangeCallback(
			pricing,
			(newPricing) => {
				// ...set the discount accordingly
				if (newPricing.finalPriceToUse == null) {
					setDiscountPercent(null)
				} else if (newPricing.basePrice == null) {
					setDiscountPercent(null)
				} else if (newPricing.finalPriceToUse > newPricing.basePrice) {
					setDiscountPercent(null)
				} else {
					let newDiscount: number | null = extractNumber(
						(
							((newPricing.basePrice - newPricing.finalPriceToUse) /
								newPricing.basePrice) *
							100
						).toFixed(1) // Get rid of weird long numbers.
					)
					if (newDiscount > 100) newDiscount = 100
					if (newDiscount === 0 || newDiscount < 0) newDiscount = null
					setDiscountPercent(newDiscount)
				}

				// ...uncheck 'Use contract pricing' if needed
				if (newPricing.finalPriceToUse !== 0 && useContract === true) {
					setUseContract(false)
				}
			},
			{ callOnSetup: true }
		)

		const exceedsWeightLimit =
			calculateCurrentWeight(selectedPart, quantity) + currentWeightTotal > weightNotToExceed

		const isEditing = !!lineItemToEdit

		const showEditPriceButton = useMemo(() => {
			// If the line is a type that can never be overridden, then no button.
			if (lineTypesCannotOverridePrice.includes(typeId)) {
				return false
			}
			// If the line type is Part, but a part hasn't been selected yet, then hide the button.
			if (typeId === LineItemType.Parts && !(selectedPart?.id || lineItemToEdit?.part?.id)) {
				return false
			}
			// If it's freight, and it doesn't have an override permission, then no button / override
			// functionality is required.
			if (typeId === LineItemType.Freight && !lineItemToEdit?.overridePermission) {
				return false
			}
			// If the line item has a pending price change, don't let them add another.
			if (lineItemToEdit?.overrideApprovalPending) {
				return false
			}
			// Some line types will always require a price.
			if (lineTypesRequireUserPrice.includes(typeId) === false) {
				return true
			}
			// Unless the line is one of the ones that cannot be overridden, then we do show the button.
			return lineTypesCannotOverridePrice.includes(typeId ?? null)
		}, [
			typeId,
			lineItemToEdit?.overridePermission,
			selectedPart?.id,
			lineItemToEdit?.part?.id,
			lineItemToEdit?.overrideApprovalPending,
		])

		/**
		 * Does the user have permission to make an override without waiting for approval?
		 */
		const overrideRequiresRequest = useMemo(() => {
			const requiredApp = lineItemToEdit?.overridePermission?.description

			return !!requiredApp && !userApps.includes(requiredApp)
		}, [userApps, lineItemToEdit?.overridePermission])

		const enableEditingPriceField = useMemo(() => {
			if (isLoadingPricing) return false
			if (lineItemToEdit?.overrideApprovalPending) return false
			if (lineTypesRequireUserPrice.includes(typeId)) return true
			// Freight is editable always, unless it has a required permission, in which case we need
			// a reason selected.
			if (typeId === LineItemType.Freight) {
				if (lineItemToEdit?.overridePermission) {
					return !!priceOverrideReason
				} else {
					return true
				}
			}
			if (isOverridingPrice && priceOverrideReason) {
				return true
			}
			if (isPurchaseOrder) {
				return true
			}

			return false
		}, [
			lineItemToEdit?.overridePermission,
			lineItemToEdit?.overrideApprovalPending,
			isLoadingPricing,
			typeId,
			isOverridingPrice,
			isPurchaseOrder,
			priceOverrideReason,
		])

		const disableQuantityField = useMemo((): boolean => {
			if (disableQuantityEditProp) return true
			if (lineTypesOneOnly.includes(typeId)) return true
			if (isDispatch && isEditing && typeId === LineItemType.Parts) {
				return true
			}

			return false
		}, [disableQuantityEditProp, typeId, isDispatch, isEditing])

		const subtotal =
			isDispatch && !isBillable ? 0 : (quantity ?? 0) * (pricing.finalPriceToUse ?? 0)

		const canSubmit = useMemo((): boolean => {
			if (pricing.finalPriceToUse == null) return false
			if (quantity == null) return false
			if (hasSubmitted.current) return false
			if (isLoadingPricing) return false
			if (!description) return false
			if (isOverridingPrice && !priceOverrideReason) return false
			if (isEditing === false && typeId === LineItemType.Parts && !selectedPart) {
				return false
			}
			if (
				isDispatch &&
				isEditing === false &&
				typeId === LineItemType.Parts &&
				!selectedBin
			) {
				return false
			}

			return true
		}, [
			pricing.finalPriceToUse,
			description,
			quantity,
			isLoadingPricing,
			isOverridingPrice,
			priceOverrideReason,
			selectedPart,
			typeId,
			isDispatch,
			isEditing,
			selectedBin,
		])

		const leftButtons: ModalProps["leftButtons"] = useMemo(() => {
			if (lineItemToEdit?.overridePermission) {
				if (userApps.includes(lineItemToEdit.overridePermission.description) === false) {
					return undefined
				}
			}

			return (
				lineItemToEdit?.id ?
					!isConfirmingRemove ?
						[
							{
								buttonText: "Remove",
								variant: "text",
								icon: "trash",
								onClick: () => setIsConfirmingRemove(true),
							},
						]
					:	[
							{
								buttonText: "Cancel",
								variant: "text",
								icon: "times",
								onClick: () => setIsConfirmingRemove(false),
							},
							{
								buttonText: "Confirm: Remove line item?",
								variant: "text",
								icon: "check",
								onClick: handleRemove,
								isLoading: isRemoving,
							},
						]
				:	undefined
			)
		}, [
			handleRemove,
			isConfirmingRemove,
			isRemoving,
			lineItemToEdit?.id,
			userApps,
			lineItemToEdit?.overridePermission,
		])

		useEffect(() => {
			setErrorText(null)
		}, [priceOverrideReason, priceOverrideOtherReason])

		return (
			<Modal
				{...rest}
				onClose={onClose}
				title={`${isEditing ? "Edit" : "New"} Line Item`}
				maxWidth="md"
				rightButtons={{
					buttonText: "Save Line Item",
					onClick: () => handleSave(false),
					disabled: !canSubmit,
					isLoading: isSaving,
					nestedButtons:
						isEditing ? undefined : (
							[
								{
									buttonText: "Save and Add Another",
									onClick: () => handleSave(true),
								},
							]
						),
				}}
				leftButtons={leftButtons}
				errorText={errorText}
			>
				{isEditing ?
					<LabeledData label="Line item" mb={2}>
						{lineItemToEdit.part ?
							`(${lineItemToEdit.part.partNumber}) ${lineItemToEdit.part.description}`
						:	description}
					</LabeledData>
				:	<>
						{!isPurchaseOrder && (
							<LineItemTypeSelector
								value={typeId}
								onChange={(id, option) =>
									handleTypeChange(id, option?.description ?? "")
								}
								fillContainer
								hideShippingExtras={hideShippingExtras}
							/>
						)}

						<AnimatedEntrance show={typeId === LineItemType.Parts}>
							<PartSelector
								value={selectedPart}
								onChange={handlePartChange}
								inputRef={partSelectorRef}
								autoFocus
								restrictionsCustomer={
									skipRestrictedCheck ? undefined : (
										shipToId || billToId || undefined
									)
								}
								skipRestrictedCheck={skipRestrictedCheck}
								restricted={excludeRestrictedParts ? false : undefined}
							/>

							{notInNetsuit && isQAOrDevelopment && (
								<div css={information}>
									<Information icon="circle-exclamation" color="warn">
										This part is not in NetSuite
									</Information>
								</div>
							)}
							<AnimatedEntrance show={isDispatch && !!selectedPart} mb={2}>
								<PartBinSelector
									value={selectedBin}
									onChange={setSelectedBin}
									partId={selectedPart?.id ?? null}
									label="Bin (required when adding part)"
									placeholder="Search for a bin..."
									hideEmptyBins
									showNoBinsMessage
								/>
							</AnimatedEntrance>
						</AnimatedEntrance>

						<AnimatedEntrance show={lineTypesRequireDescription.includes(typeId)}>
							<TextInput
								value={description}
								onChange={setDescription}
								label="Description (required)"
								placeholder="Description..."
								maxLength={100}
							/>
						</AnimatedEntrance>
					</>
				}

				<div>
					<LabeledData
						label="Original price"
						isLoading={isLoadingPricing}
						paragraphProps={isLoadingPricing ? { height: 2, width: 10 } : undefined}
					>
						{pricing.basePrice != null ?
							formatCurrency(pricing.basePrice)
						:	<EmptyValueDash />}
					</LabeledData>
					{useContract != null && (
						<Checkbox
							display="inline-block"
							value={!!useContract && !isOverridingPrice}
							onChange={handleUseContractToggle}
							label="Use contract price: $0"
							disabled={isOverridingPrice}
							mt={-0.5}
						/>
					)}
				</div>

				<GridContainer>
					<GridItem xs={6} sm={4}>
						<NumericInput
							label="Discount percent %"
							value={discountPercent || ""}
							onBlur={(e) => onDiscountBlur(e.target.value)}
							onChange={(newValue) => setDiscountPercent(newValue ?? null)}
							decimalScale={1}
							allowNegative={false}
							disabled={!enableEditingPriceField}
						/>
					</GridItem>
					<GridItem xs={6} sm={4}>
						<NumericInput
							label="Net price $"
							min={0}
							value={pricing.finalPriceToUse ?? ""}
							onChange={(newValue) =>
								setPricing((prev) => ({
									...prev,
									// The discount price will be just be put directly in final price to use.
									// Upon saving, we will determine if it actually needs to be just a requested
									// price override price.
									finalPriceToUse: newValue ?? null,
								}))
							}
							decimalScale={2}
							allowNegative={false}
							disabled={!enableEditingPriceField}
						/>
					</GridItem>
					<GridItem xs={6} sm={4}>
						<NumericInput
							label="Quantity"
							value={quantity}
							onChange={(newValue) => setQuantity(newValue ?? null)}
							decimalScale={2}
							disabled={disableQuantityField}
						/>
						{!!disableQuantityEditProp && (
							<Paragraph small color="secondary">
								Editing quantities on existing line items has temporarily been
								disabled.
							</Paragraph>
						)}
					</GridItem>
				</GridContainer>

				{showEditPriceButton &&
					(isOverridingPrice ?
						<AnimatedEntrance show direction="down">
							<PriceOverrideReasonSelector
								value={priceOverrideReason?.id.toString() ?? null}
								onChange={(...[, option]) =>
									handleSetPriceOverrideReason(option ?? null)
								}
								otherReasonValue={priceOverrideOtherReason}
								otherReasonOnChange={setPriceOverrideOtherReason}
								typeFilter={
									lineTypesFreightish.includes(typeId) ?
										PriceOverrideReasonTypeId.Freight
									:	PriceOverrideReasonTypeId.Default
								}
								disabled={lineItemToEdit?.overrideApprovalPending}
							/>
							<div>
								{!lineItemToEdit?.overrideApprovalPending && (
									<Button
										icon="times"
										onClick={handleRemovePriceOverride}
										disabled={lineItemToEdit?.overrideApprovalPending}
									>
										{overrideRequiresRequest ?
											"Remove price edit request"
										:	"Remove price edit"}
									</Button>
								)}
							</div>
						</AnimatedEntrance>
					:	<Button
							icon="pencil"
							onClick={() => setIsOverridingPrice(true)}
							disabled={isLoadingPricing}
						>
							{overrideRequiresRequest ? "Request price edit" : "Edit price"}
						</Button>)}

				{isDispatch && (
					<Box mt={2}>
						<Paragraph>Is this line item billable, or under warranty?</Paragraph>
						<Checkbox
							label="Billable"
							value={isBillable}
							onChange={(isChecked) => {
								setIsBillable(isChecked)
								if (isChecked) {
									setIsUnderWarranty(false)
								}
							}}
							mb={0}
						/>
						<Checkbox
							label="Under warranty"
							value={isUnderWarranty}
							onChange={(isChecked) => {
								setIsUnderWarranty(isChecked)
								if (isChecked) {
									setIsBillable(false)
								}
							}}
							mb={0}
						/>
					</Box>
				)}

				<AnimatedEntrance show={exceedsWeightLimit} mt={2} direction="down">
					<Callout variant="warning" icon="exclamation-triangle">
						<Paragraph small>
							Warning: The selected part at this quantity puts the total weight of
							this order over the truck weight limit of{" "}
							{formatNumber(weightNotToExceed)}. Please consider submitting multiple
							orders instead.
						</Paragraph>
					</Callout>
				</AnimatedEntrance>

				<Divider />
				<Box d="flex" justifyContent="flex-end" gap={1}>
					{lineItemToEdit?.overrideApprovalPending && (
						<Callout icon="user-lock" variant="info">
							Price change pending approval
						</Callout>
					)}
					{isDispatch && !isBillable && (
						<Callout icon="info-circle" variant="info">
							Non-billable line items will always have a price of $0
						</Callout>
					)}
					<div>
						<Label>Line item subtotal</Label>
						<Price textAlign="right" price={subtotal} isLoading={isLoadingPricing} />
					</div>
				</Box>
			</Modal>
		)
	}
)

const calculateCurrentWeight = (
	partToCalc: InventoryPart | null,
	quantityToCalc: number | null
) => {
	return (partToCalc?.weight ?? 0) * (quantityToCalc ?? 0)
}

interface PricingState {
	/**
	 * The price that drives the Net Price text input.
	 */
	finalPriceToUse: number | null
	/**
	 * The actual, final price for the line item BUT without any edits from the user via this modal.
	 */
	finalPriceOriginal: number | null
	/**
	 * Our best attempt at a generic price for the line, without overrides or customer discount.
	 */
	basePrice: number | null
	/**
	 * The tax rate percentage, expressed as a decimal.
	 */
	taxRate: number | null
}

LineItemEditorModal.displayName = "LineItemEditorModal"

const information = () => css`
	margin-bottom: 1rem;
`
