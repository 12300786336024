import { z } from "zod"

export enum APPLICATION {
	BetaFeatures = "Beta Features",
	BudgetAdmin = "Budget Admin",
	Checkout = "Checkout",
	ChemBlend = "Chem Blend",
	ChemicalMachineTypeAdministrator = "Chemical Machine Type Administrator",
	ChemicalMachineTypeViewer = "Chemical Machine Type Viewer",
	ChemicalOrders = "Chemical Orders",
	ChemicalService = "Chemical Service",
	ChemMachineTypeAdmin = "Chemical Machine Type Administrator",
	ChemMachineTypeViewer = "Chemical Machine Type Viewer",
	ChemOrder = "Chem Order",
	Connectivity = "Connectivity",
	ContractApprover = "Contract Approver",
	ContractCreator = "Contract Creator",
	ContractViewer = "Contract Viewer",
	ContractWashCounts = "Contract Wash Counts",
	CreateDispatch = "Create Dispatch",
	CreateWorkorder = "Create Workorder",
	CreditMemoApproval = "Credit Memo Approval",
	CrossRefManagement = "Cross Ref Management",
	CsFreightLineApprover = "CS Freight Line Approver",
	CustomerCreate = "Customer Create",
	CustomerFreightExceptions = "Customer Freight Exceptions",
	CustomerHierarchy = "Customer Hierarchy",
	CustomerInfoApproval = "Customer Info Approval",
	CustomerManagement = "Customer Management",
	CustomerManagementExport = "Customer Management Export",
	CustomerManagementReportsAdmin = "Customer Management Reports Admin",
	CustomerManagementBulkUploads = "Customer Management Bulk Uploads",
	CustomReports = "Custom Reports",
	DeliveryOrder = "Delivery Order",
	Equipment = "Equipment",
	EquipmentDocumentation = "Equipment Documentation",
	EquipmentManagement = "Equipment Management",
	EquipmentRepairProposal = "Equipment Repair Proposal",
	ERP = "Equipment Repair Proposal",
	FactoryDashboard = "Factory Dashboard",
	FactoryDashboardSetup = "Factory Dashboard Setup",
	FinanceSuperUser = "Finance Super User",
	FormsAdministrator = "Forms Administrator",
	FreightPriceOverride = "Freight Price Override",
	FreightPricingViewer = "Freight Pricing Viewer",
	FreightRatesAdmin = "Freight Rates Admin",
	GeodisOverride = "Geodis Override",
	HidePaymentInfo = "Hide Payment Info",
	InfoRefAdmin = "Information Reference Administrator",
	InfoRefViewer = "Information Reference Viewer",
	InformationReferenceAdministrator = "Information Reference Administrator",
	InformationReferenceViewer = "Information Reference Viewer",
	Intransit = "Intransit",
	InTransit = "Intransit", // cspell:disable-line -- [sic]
	InventoryBinLocksAdmin = "Inventory Bin Locks Admin",
	InventoryManagement = "Inventory Management",
	InventoryTransactions = "Inventory Transactions",
	InventoryTransactionsCreditStockAndLoss = "Inventory Transactions Credit Stock and Loss",
	InvoiceApproval = "Invoice Approval",
	InvoicePrint = "Invoice Print",
	Invoices = "Invoices",
	ITSupport = "IT Support",
	LaborPricingAdmin = "Labor Pricing Admin",
	LaborRateAdmin = "Labor Rate Admin",
	LaborRateUser = "Labor Rate User",
	LocallySourcedPart = "Locally Sourced Part",
	LocalPartManagement = "Local Part Management",
	MachineAdmin = "Machine Admin",
	MachineCodes = "Machine Codes",
	MachineMilestone = "Machine Milestone",
	MachineMilestoneAdmin = "Machine Milestone Admin",
	ManufacturedParts = "Manufactured Parts",
	Mapping = "Mapping",
	MappingAdmin = "Mapping Admin",
	MarketingMaterialAdmin = "Marketing Material Admin",
	MarketingMaterialUser = "Marketing Material User",
	NegativeOnHand = "Negative OnHand",
	OpsFreightLineApprover = "Ops Freight Line Approver",
	OtherDocumentation = "Other Documentation",
	PackageShipping = "Package Shipping",
	PartCrossReference = "Part Cross Reference",
	PartDiscountAdmin = "Part Discount Admin",
	PartManagementAdmin = "Part Management Admin",
	PartOrderHoldStatus = "Part Order Hold Status",
	PartReplacement = "Part Replacement",
	PartsOrder = "Parts Order",
	PartsOrderDelivery = "Parts Order Delivery",
	PhysicalInventory = "Physical Inventory",
	PhysicalInventoryAdministrator = "Physical Inventory Administrator",
	PhysicalInventoryUser = "Physical Inventory User",
	PickList = "Pick List",
	PointOfSaleAdmin = "Point of Sale Admin",
	PointOfSaleUser = "Point of Sale User",
	POSuperuser = "PO Superuser",
	PourDown = "Pour Down",
	PriceAdjustmentRequests = "Price Adjustment Requests",
	ProductionBoardAdmin = "Production Board Admin",
	ProductionBoardUser = "Production Board User",
	RDCManager = "RDC Manager",
	ReassignOrderDc = "Reassign Order DC",
	RepairPartOrder = "Repair Part Order",
	Replenishment = "Replenishment",
	RetailSales = "Retail Sales",
	SearchDispatch = "Search Dispatch",
	Shipping = "Shipping",
	SiteProfit = "Site Profit",
	SiteProfitability = "Site Profitability",
	SiteProfitabilityTemp = "Site Profitability Temp",
	SiteSurveyAdmin = "Site Survey Admin",
	SiteSurveyUser = "Site Survey User",
	SupportIssue = "Support Issue",
	TechnicianAssignment = "Technician Assignment",
	TicketingAdmin = "Ticketing Admin",
	TicketingFinanceAdmin = "Ticketing Finance Admin",
	TicketingFinanceUser = "Ticketing Finance User",
	TicketingITUser = "Ticketing IT User",
	TicketingSuperUser = "Ticketing Super User",
	TicketingSuperuser = "Ticketing Superuser",
	TicketingTechSupportUser = "Ticketing Tech Support User",
	TicketingUser = "Ticketing User",
	TimeAndAttendance = "Time and Attendance",
	TraxAdmin = "Trax Admin",
	TraxDemo = "Trax Demo",
	TraxSetup = "Trax Setup",
	TSMUserManagement = "TSM User Management",
	UserManagement = "User Management",
	UserManagementAdmin = "User Management Admin",
	UserManagementCreateCustomer = "User Management Create Customer",
	UserSchedule = "User Schedule",
	VacuumAdmin = "Vacuum Admin",
	VendorManagement = "Vendor Management",
	WashCounterAdmin = "Wash Counter Admin",
	WashCountSubmission = "Wash Count Submission",
	WebImagesAdministrator = "Web Images Administrator",
	WorkOrders = "Work Orders",
	ILoveBulkImports = "I Love Bulk Imports",
	CancelCycleCounts = "Cancel Cycle Counts",
	PickListDownloader = "Pick List Downloader",
	FuelSurchargeEditor = "Fuel Surcharge Editor",
}

export const UserSchema = z.object({
	id: z.string(),
	email: z.string().email(),
	name: z.string(),
	apps: z.array(z.nativeEnum(APPLICATION)),
})

export type User = z.infer<typeof UserSchema>

export const LoginRequestSchema = z.object({
	email: z.string().email(),
	password: z.string().min(1).max(255),
})

export const LoginResponseSchema = z.object({
	access: z.string().min(1).max(8191),
	refresh: z.string().min(1).max(8191),
})

export const RefreshRequestSchema = z.object({
	refresh: z.string().min(1).max(8191),
})

export type LoginResponse = z.infer<typeof LoginResponseSchema>
