import { FC, useMemo, useState } from "react"

import { css } from "@emotion/react"

import {
	APPLICATION,
	CustomerDetail,
	FreightClassId,
	InventoryLocation,
	OrganizationMinimal,
	UpdateCustomerPatch,
	useCustomerSubclasses,
	useUserCanUse,
} from "@ncs/ncs-api"
import { tryToFormatPhone } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Checkbox,
	EmptyValueDash,
	encodeUrlState,
	getAddressFields,
	GridContainer,
	GridItem,
	Label,
	LabeledData,
	Link,
	LocationSelector,
	OrganizationSelector,
	PhoneInput,
	ParagraphList,
	TextInput,
	useChangeCallback,
	AddressFormModal,
	Button,
} from "@ncs/web-legos"

import {
	CustomerClassSelector,
	CustomerSubclassSelector,
	CustomerTypeSelector,
	FreightClassSelector,
} from "~/components"
import { OrganizationsTabUrlState } from "~/views/Customer/EnterprisesAndOrganizations/components"
import {
	EnterprisesAndOrganizationsTab,
	EnterprisesAndOrganizationsUrlState,
} from "~/views/Customer/EnterprisesAndOrganizations/EnterprisesAndOrganizations"

export interface DetailsTabAboutProps {
	customer: CustomerDetail
	update: (updates: Partial<UpdateCustomerPatch>) => Promise<void>
}

export const DetailsTabAbout: FC<DetailsTabAboutProps> = ({ customer, update }) => {
	const isQAOrDevelopment =
		process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development"
	const existsInNetsuite = customer?.netsuiteId !== null && isQAOrDevelopment

	const isRdcManager = useUserCanUse(APPLICATION.RDCManager)
	const isReportsAdmin = useUserCanUse(APPLICATION.CustomerManagementReportsAdmin)
	const [newDc, setNewDc] = useState<InventoryLocation | null>(null)
	const [newOrganization, setNewOrganization] = useState<OrganizationMinimal | null>(null)
	const [newType, setNewType] = useState<string | null>(customer.customerType?.id ?? null)
	const [newPhoneNumber, setNewPhoneNumber] = useState<string | null>(customer.phone ?? null)
	const [newName, setNewName] = useState<string | null>(customer.name ?? null)
	const [showAddressFormModal, setShowAddressFormModal] = useState(false)
	const [contactName, setContactName] = useState<string | null>(customer.contactName ?? null)
	const [poRequired, setPoRequired] = useState<boolean | null>(customer.poRequired ?? null)

	const [newClass, setNewClass] = useState<string | null>(customer.customerClass?.id ?? null)
	const [newSubclass, setNewSubclass] = useState<string | null>(
		customer.customerSubclass?.id ?? null
	)
	const [newFreightClass, setNewFreightClass] = useState<FreightClassId | null>(
		customer.freightClassId ?? null
	)
	const [isSaving, setIsSaving] = useState<(keyof UpdateCustomerPatch)[]>([])

	const [subclasses, subclassesLoading] = useCustomerSubclasses()
	const newClassHasSubclasses = useMemo(() => {
		return !!newClass && (subclasses ?? []).some((subC) => subC.customerClass.id === newClass)
	}, [newClass, subclasses])

	const handleSaveClasses = async () => {
		let subclassId: string | null = null

		// If there's a new subclass selected, we should verify that its parent class is
		// what is selected.
		if (newClass && newSubclass) {
			const subclass = (subclasses ?? []).find((subC) => subC.id === newSubclass)
			if (subclass?.customerClass.id === newClass) {
				subclassId = newSubclass
			}
		}

		await update({
			customerClassId: newClass,
			customerSubclassId: subclassId,
		})
	}

	useChangeCallback(customer, (newCustomer) => {
		setNewDc(null)
		setNewOrganization(null)
		setNewType(newCustomer.customerType?.id ?? null)
		setNewClass(newCustomer.customerClass?.id ?? null)
		setNewSubclass(newCustomer.customerSubclass?.id ?? null)
	})

	return (
		<>
			<GridContainer alignItems="baseline" rowGap={0}>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Site name"
						editingRender={
							isReportsAdmin && customer.isInternal ?
								() => <TextInput value={newName} onChange={setNewName} />
							:	undefined
						}
						onSaveEdit={async () => {
							setIsSaving((prev) => [...prev, "name"])
							await update({ name: newName })
							setIsSaving((prev) => prev.filter((p) => p !== "name"))
						}}
						isSavingEdit={isSaving.includes("name")}
						onCancelEdit={() => setNewName(customer.name)}
					>
						{newName || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData label="Site #">
						{customer.siteNumber || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Phone"
						editingRender={
							isReportsAdmin ?
								() => (
									<PhoneInput
										value={newPhoneNumber}
										onChange={setNewPhoneNumber}
										placeholder="Eg, (777) 777-7777"
									/>
								)
							:	undefined
						}
						onSaveEdit={async () => {
							setIsSaving((prev) => [...prev, "phone"])
							await update({ phone: newPhoneNumber })
							setIsSaving((prev) => prev.filter((p) => p !== "phone"))
						}}
						isSavingEdit={isSaving.includes("phone")}
						onCancelEdit={() => setNewPhoneNumber(customer.phone)}
					>
						{tryToFormatPhone(customer.phone) || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData label="Email">{customer.email || <EmptyValueDash />}</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Contact name"
						editingRender={
							isReportsAdmin ?
								() => <TextInput value={contactName} onChange={setContactName} />
							:	undefined
						}
						onSaveEdit={async () => {
							setIsSaving((prev) => [...prev, "contactName"])
							await update({ contactName: contactName })
							setIsSaving((prev) => prev.filter((p) => p !== "contactName"))
						}}
						isSavingEdit={isSaving.includes("contactName")}
						onCancelEdit={() => setNewPhoneNumber(customer.phone)}
					>
						{customer.contactName || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						display="flex"
						label="Parent organization"
						editingRender={() => (
							<OrganizationSelector
								value={newOrganization}
								onChange={setNewOrganization}
								initialId={customer.organization?.id}
								fillContainer
								label=""
							/>
						)}
						onSaveEdit={async () => {
							setIsSaving((prev) => [...prev, "organizationId"])
							await update({ organizationId: newOrganization?.id ?? null })
							setIsSaving((prev) => prev.filter((p) => p !== "organizationId"))
						}}
						isSavingEdit={isSaving.includes("organizationId")}
					>
						{customer.organization ?
							<Link
								newTab
								icon="external-link"
								to={`/customers/enterprises-and-organizations${encodeUrlState<
									EnterprisesAndOrganizationsUrlState & OrganizationsTabUrlState
								>({
									tab: EnterprisesAndOrganizationsTab.Organizations,
									organizationId: customer.organization.id,
								})}`}
							>
								{customer.organization.name}
							</Link>
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData label="Territory">
						{customer.territory ?
							`(${customer.territory.code}) ${customer.territory.description}`
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						display="flex"
						label="Default distribution center"
						editingRender={() =>
							isRdcManager ?
								<LocationSelector
									value={newDc}
									onChange={setNewDc}
									initialId={customer.rdc?.id}
									fillContainer
									label=""
								/>
							:	undefined
						}
						onSaveEdit={
							isRdcManager ?
								async () => {
									setIsSaving((prev) => [...prev, "rdcId"])
									await update({ rdcId: newDc?.id ?? null })
									setIsSaving((prev) => prev.filter((p) => p !== "rdcId"))
								}
							:	undefined
						}
						isSavingEdit={isSaving.includes("rdcId")}
					>
						{customer.rdc ?
							`(${customer.rdc.locationCode}) ${customer.rdc.description}`
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Customer type"
						editingRender={
							existsInNetsuite && isReportsAdmin ?
								() => (
									<CustomerTypeSelector
										value={newType}
										onChange={setNewType}
										label={null}
										fillContainer
									/>
								)
							:	undefined
						}
						onSaveEdit={async () => {
							await update({ customerTypeId: newType })
						}}
						onCancelEdit={() => setNewType(customer.customerType?.id ?? null)}
					>
						{customer.customerType?.description || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Customer class"
						editingRender={
							isReportsAdmin ?
								() => (
									<>
										<CustomerClassSelector
											value={newClass}
											onChange={setNewClass}
											label={null}
											fillContainer
										/>
										<AnimatedEntrance
											show={newClassHasSubclasses}
											direction="down"
										>
											<CustomerSubclassSelector
												value={newSubclass}
												onChange={setNewSubclass}
												customerClassId={newClass}
												label="Choose subclass (required)"
												mb={1.25}
												fillContainer
											/>
										</AnimatedEntrance>
									</>
								)
							:	undefined
						}
						onSaveEdit={async () => {
							await handleSaveClasses()
						}}
						onCancelEdit={() => setNewClass(customer.customerClass?.id ?? null)}
						validateEdit={() => {
							if (!newClass) return true
							if (subclassesLoading) return false
							if (newClassHasSubclasses && !newSubclass) return false
							return true
						}}
					>
						{customer.customerClass?.description || <EmptyValueDash />}
						{!!customer.customerSubclass && (
							<span
								css={css`
									display: block;
								`}
							>
								{customer.customerSubclass.description}
							</span>
						)}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<Label>Address</Label>
					{customer.isInternal && isReportsAdmin ?
						<>
							<ParagraphList
								lines={getAddressFields(customer, { exclude: "name" })}
							/>
							<Button
								icon="pencil"
								onClick={() => setShowAddressFormModal(true)}
							></Button>
						</>
					:	<>
							<ParagraphList
								lines={getAddressFields(customer, { exclude: "name" })}
							/>
						</>
					}
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						// need to make sure Equipment type is enabled when Netsuite go live
						label={isQAOrDevelopment ? "Equipment type" : "Freight class"}
						editingRender={
							isReportsAdmin ?
								() => (
									<FreightClassSelector
										value={newFreightClass}
										onChange={setNewFreightClass}
										fillContainer
										label={null}
									/>
								)
							:	undefined
						}
						onSaveEdit={async () => {
							await update({ freightClassId: newFreightClass })
						}}
						onCancelEdit={() => setNewFreightClass(customer.freightClassId)}
					>
						{customer.freightClass || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<Label>Is PO required?</Label>
					<Checkbox
						label="PO required"
						value={customer.poRequired}
						onChange={async (isChecked) => {
							setPoRequired(isChecked)
							setIsSaving((prev) => [...prev, "poRequired"])
							await update({ poRequired: !poRequired })
						}}
						mb={0}
					/>
				</GridItem>
			</GridContainer>
			<AddressFormModal
				onSave={async (address) => {
					setIsSaving((prev) => [
						...prev,
						"address1",
						"address2",
						"city",
						"state",
						"postalcode",
					])
					await update({
						address1: address.address1,
						address2: address.address2,
						city: address.city,
						state: address.state,
						postalcode: address.zip,
					}).finally(() => {
						setIsSaving((prev) =>
							prev.filter(
								(p) =>
									p !== "address1" &&
									p !== "address2" &&
									p !== "city" &&
									p !== "state" &&
									p !== "postalcode"
							)
						)
						setShowAddressFormModal(false)
					})
				}}
				hardCodedFields={{ name: customer.name }}
				isOpen={showAddressFormModal}
				onClose={() => setShowAddressFormModal(false)}
			/>
		</>
	)
}
