import uniq from "lodash/uniq"
import qs from "query-string"
import { z } from "zod"

import { BrandCard, BrandCardPatch, BrandCardPost, ChemicalCategoryId } from "@ncs/ncs-api"
import { arrayWrap, noNullish } from "@ncs/ts-utils"

export const BrandCardFormSchema = z.object({
	name: z.string().min(1, "Required"),
	taglineText: z.string().min(1, "Required"),
	buttonDestinationUrl: z.string().min(1, "Required"),
	logoImageUrl: z.string().min(1, "Required"),
	buttonDestinationOpensNewWindow: z.boolean(),
	isActive: z.boolean(),
})

export type BrandCardForm = z.infer<typeof BrandCardFormSchema>

export const brandCardFormDefaults: BrandCardForm = {
	name: "",
	taglineText: "",
	buttonDestinationUrl: "",
	logoImageUrl: "",
	buttonDestinationOpensNewWindow: false,
	isActive: true,
}

export const getFormValuesFromCard = (card?: BrandCard): BrandCardForm => {
	const result: BrandCardForm = {
		name: card?.name || brandCardFormDefaults.name,
		taglineText: card?.taglineText || brandCardFormDefaults.taglineText,
		buttonDestinationUrl:
			card?.buttonDestinationUrl || brandCardFormDefaults.buttonDestinationUrl,
		logoImageUrl: card?.logoImageUrl || brandCardFormDefaults.logoImageUrl,
		buttonDestinationOpensNewWindow:
			card?.buttonDestinationOpensNewWindow ??
			brandCardFormDefaults.buttonDestinationOpensNewWindow,
		isActive: card?.isActive ?? brandCardFormDefaults.isActive,
	}

	return result
}

export enum SearchFilter {
	Brand = "brand",
	System = "system",
	Category = "category",
	Chemical = "chemical",
}

export type CurrentFiltersState = Record<SearchFilter, string[]>

export const getFilterStateFromUrl = (url: string): CurrentFiltersState => {
	const { query } = qs.parseUrl(url, {
		arrayFormat: "comma",
	})

	// Because chemical IDs are also just stored in the categories array, we need
	// to know which IDs to put into chemicals instead.
	const chemicalCategoryIds: (string | null)[] = Object.values(ChemicalCategoryId)

	return {
		[SearchFilter.Brand]: noNullish(arrayWrap(query["manufacturers"])),
		[SearchFilter.System]: noNullish(arrayWrap(query["systems"])),
		[SearchFilter.Category]: noNullish(
			arrayWrap(query["categories"]).filter((id) => !chemicalCategoryIds.includes(id))
		),
		[SearchFilter.Chemical]: noNullish(
			arrayWrap(query["categories"]).filter((id) => chemicalCategoryIds.includes(id))
		),
	}
}

export const getUrlFromFilterState = (
	baseUrl: string,
	filterState: CurrentFiltersState
): string => {
	// Before we stringify the URL, we need to collapse categories and chemicals
	// into just categories.
	const preparedFilterState = {
		manufacturers: filterState.brand,
		systems: filterState.system,
		categories: uniq([...filterState.category, ...filterState.chemical]),
	}

	return qs.stringifyUrl(
		{
			url: baseUrl,
			query: preparedFilterState,
		},
		{ arrayFormat: "comma" }
	)
}

export const isSearchPageUrl = (url: string): boolean => {
	const startsWith = [
		"/shop/search",
		"https://pluto.ncsinternal.com/shop/search",
		"https://portal.ncsinternal.com/shop/search",
		"portal.ncsinternal.com/shop/search",
	]

	return startsWith.some((chunk) => url.startsWith(chunk))
}

export const brandCardFormToBrandCardPayload = (
	form: BrandCardForm,
	presentationOrder: number // Let this be decided at call time.
): BrandCardPost | BrandCardPatch => {
	const result: BrandCardPost | BrandCardPatch = {
		...form,
		presentationOrder,
		// The new tab option is for custom URLs, not search page.
		buttonDestinationOpensNewWindow:
			isSearchPageUrl(form.buttonDestinationUrl) ? false : (
				form.buttonDestinationOpensNewWindow
			),
	}

	return result
}
