import { Dispatch, FC, memo, SetStateAction } from "react"

import { css } from "@emotion/react"
import { useFormContext } from "react-hook-form"

import { PosPart } from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import { Box, FileInput, FileType, ImageFileReader, Paragraph, PartImage } from "@ncs/web-legos"

import { PosPartForm } from "./pos-products-utils"

export interface PosPartImageProps {
	part: PosPart | null
	newImageFile: File | null
	setNewImageFile: Dispatch<SetStateAction<File | null>>
}

export const PosPartImage: FC<PosPartImageProps> = memo(
	({ part, newImageFile, setNewImageFile }) => {
		const { watch } = useFormContext<PosPartForm>()
		const [imageUrl, relationship] = watch(["imageUrl", "relationship"])

		const isParent = relationship === "parent"

		return (
			<>
				<Box mb={1}>
					{newImageFile && !isParent ?
						<ImageFileReader file={newImageFile} />
					: imageUrl ?
						<PartImage src={imageUrl} />
					:	<div css={noImageContainerStyle}>
							<Paragraph small color="secondary" textAlign="center" p={1}>
								(None set)
							</Paragraph>
						</div>
					}
				</Box>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					mb={1}
					flexWrap="wrap"
				>
					{part?.imageLastModified && (
						<Paragraph small color="secondary">
							Image last modified {formatDateTime(part.imageLastModified)}
						</Paragraph>
					)}
				</Box>

				{!isParent && (
					<FileInput
						onChange={setNewImageFile}
						label="Select new image"
						fileTypes={[FileType.Images]}
					/>
				)}
			</>
		)
	}
)

const noImageContainerStyle = css`
	display: block;
	margin-bottom: 1rem;
	border: 1px solid #eee;
	width: 100%;
`

PosPartImage.displayName = "PossPartDetailImage"
