import "@ncs/bricks/assets/scss/material-dashboard-pro-react.css?v=1.9.0"

import React from "react"
// eslint-disable-next-line react/no-deprecated
import { render } from "react-dom"

import { library } from "@fortawesome/fontawesome-svg-core"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { createBrowserHistory } from "history"
import { enableAllPlugins } from "immer"

import { setMiddlewareBaseUrl } from "@ncs/bricks/config"
import { setBaseUrl } from "@ncs/ncs-api"
import { faIcons as legoIcons, setUspsUserName } from "@ncs/web-legos"

import configureStore from "./store"
import { faIcons as kittyCatIcons } from "./util"
import AppProvider from "./views/Auth/AppProvider"

enableAllPlugins()

const history = createBrowserHistory()
const store = configureStore(history)

library.add(...legoIcons, ...kittyCatIcons)

setBaseUrl(import.meta.env.VITE_PORTAL_SERVICE_URL)
setMiddlewareBaseUrl(import.meta.env.VITE_PORTAL_SERVICE_URL)
setUspsUserName(import.meta.env.VITE_USPS_USER_NAME)

let app = <AppProvider store={store} history={history} />

Sentry.init({
	enabled: !!import.meta.env.VITE_SENTRY_DSN,
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.1,
})

app = (
	<Sentry.ErrorBoundary
		fallback={
			<p>
				Whoops, an error has occurred! You can try refreshing the page and starting again.
			</p>
		}
	>
		{app}
	</Sentry.ErrorBoundary>
)

render(app, document.getElementById("root"))
