import { z } from "zod"

import {
	Customer,
	CustomerAddressWithoutFst,
	CustomerAlternateAddress,
	CustomerBillTo,
	CustomerSite,
	CustomerWithAddress,
	LaborPricingDetails,
	OrganizationCustomer,
} from "@ncs/ncs-api"
import { arrayWrap, isCanadianProvince } from "@ncs/ts-utils"

import { UspsAddress } from "./usps-address-verify"

export type AnyNcsApiObjectWithAddress =
	| GenericAddress
	| Customer
	| CustomerBillTo
	| CustomerWithAddress
	| CustomerSite
	| CustomerAlternateAddress<string>
	| CustomerAlternateAddress<number>
	| CustomerAddressWithoutFst
	| UspsAddress
	| OrganizationCustomer
	| LaborPricingDetails["customers"][number]

// Note that cities, states, and zips as empty strings are rife within the DB, so
// what you get back can definitely have them too.
export const GenericAddressSchema = z.object({
	name: z.string().min(1),
	address1: z.string().nullish(),
	address2: z.string().min(1),
	city: z.string(),
	state: z.string(),
	zip: z.string(),
	country: z.string().nullish(),
	id: z.string().nullable(),
	customerNumber: z.string().nullable(),
	latitude: z.string().nullable(),
	longitude: z.string().nullable(),
	postalCode: z.string().nullable(),
	phone: z.string().nullable(),
})

export type GenericAddress = z.infer<typeof GenericAddressSchema>

export type GetGenericAddressOptions = Partial<{
	suppressWarnings: boolean
}>

const defaultGetGenericAddressOptions: GetGenericAddressOptions = {
	suppressWarnings: false,
}

export const getGenericAddress = (
	customer: AnyNcsApiObjectWithAddress,
	optionsArg?: GetGenericAddressOptions
): GenericAddress => {
	const options = {
		...defaultGetGenericAddressOptions,
		...optionsArg,
	}

	// Kinda weird, but zip can be a bunch of different things so just go through them and see what you get.
	const customerZip =
		(customer as { postalcode?: string }).postalcode ||
		(customer as { postalCode?: string }).postalCode ||
		(customer as { zip?: string }).zip ||
		""

	// Assemble it so far and give a console warning if it's wrong somehow.
	const addressRaw = {
		name: customer.name,
		address1: customer.address1,
		address2: customer.address2,
		city: customer.city,
		state: customer.state,
		zip: customerZip,
	}
	const check = GenericAddressSchema.safeParse(addressRaw)
	if (!check.success && !options?.suppressWarnings) {
		console.warn("Unexpected address formatting: ", check.error)
	}

	// Sometimes address2 and address1 were entered backwards and are switched.
	// If address2 is falsy, then use address1 if it truthy.
	let address1 = customer.address1
	let address2 = customer.address2
	if (!customer.address2 && !!customer.address1) {
		address1 = customer.address2
		address2 = customer.address1
	}

	return {
		name: customer.name ?? "",
		address1: address1 ?? null,
		address2: address2 ?? "",
		city: customer.city ?? "",
		state: customer.state ?? "",
		zip: customerZip ?? "",
	} as CustomerSite
}

export type AddressFieldsOptions = Partial<{
	combineCityState: boolean
	exclude: keyof GenericAddress | (keyof GenericAddress)[]
	include: keyof GenericAddress | (keyof GenericAddress)[]
	addCountryIfCanadian: boolean
	suppressWarnings: boolean
}>

const defaultAddressFieldsOptions: AddressFieldsOptions = {
	combineCityState: true,
	addCountryIfCanadian: true,
}

/**
 * Take object that has an address in it and return an array of its fields.
 * Returns an empty array if the object is nullish.
 */
export const getAddressFields = (
	address: AnyNcsApiObjectWithAddress | null | undefined,
	optionsArg?: AddressFieldsOptions,
	phoneNumber?: string | null | undefined
): string[] => {
	const results: string[] = []

	if (!address) return results

	const options: AddressFieldsOptions = {
		...defaultAddressFieldsOptions,
		...optionsArg,
	}

	const defaultIncludeList: (keyof GenericAddress)[] = [
		"name",
		"address1",
		"address2",
		"city",
		"state",
		"zip",
	]

	const fields = getGenericAddress(address, {
		suppressWarnings: options.suppressWarnings,
	})

	const includeList = arrayWrap(options.include ?? defaultIncludeList)
	const excludeList = arrayWrap(options.exclude ?? [])
	const fieldList = includeList.filter((field) => !excludeList.includes(field))

	if (fieldList.includes("name") && !!fields.name) results.push(fields.name)
	if (fieldList.includes("address1") && !!fields.address1) results.push(fields.address1)
	if (fieldList.includes("address2") && !!fields.address2) results.push(fields.address2)
	if (!options.combineCityState) {
		if (fieldList.includes("city") && !!fields.city) results.push(fields.city)
		if (fieldList.includes("state") && !!fields.state) results.push(fields.state)
	} else {
		const city = fieldList.includes("city") && !!fields.city ? fields.city : null
		const state = fieldList.includes("state") && !!fields.state ? fields.state : null
		if (city && state) {
			results.push(`${city}, ${state}`)
		} else {
			if (city) results.push(city)
			if (state) results.push(state)
		}
	}
	if (fieldList.includes("zip") && !!fields.zip) results.push(fields.zip)

	// If it's a Canadian province, add country to the end.
	if (options.addCountryIfCanadian === true && isCanadianProvince(fields.state)) {
		results.push("Canada")
	}

	if (phoneNumber) {
		results.push(phoneNumber)
	}

	return results
}
