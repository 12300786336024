import { FC } from "react"
import { css } from "@emotion/react"
import { useFormContext } from "react-hook-form"
import { Paragraph } from "@ncs/web-legos"
import { BrandCardForm } from "./brand-card-utils"

export const BrandCardPreview: FC = () => {
	const { watch } = useFormContext<BrandCardForm>()
	const [logoImageUrl, taglineText] = watch(["logoImageUrl", "taglineText"])

	return (
		<div css={containerCss}>
			{logoImageUrl ?
				<div css={container}>
					<img src={logoImageUrl} alt="Logo" css={logoCss} />
					<Paragraph mt={1}>{taglineText}</Paragraph>
				</div>
			:	<Paragraph small color="secondary" textAlign="center">
					(Logo will show here)
				</Paragraph>
			}
		</div>
	)
}

const containerCss = css`
	max-width: 25rem;
	padding: 1.5rem;
	border-radius: 0 25px 0 25px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	display: flex;
	justify-content: center;
`
const logoCss = css`
	display: block;
	margin: 0 auto;
`

const container = css`
	display: flex;
	flex-direction: column;
	align-items: center;
`
