import { GenericTaxLineItem } from "../../util/line-items"
import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { APPLICATION } from "../auth"
import { LineItemType } from "../common"
import { FreightClassId } from "../customers"

export enum PricingUrlPaths {
	AddCustomer = "add_customer",
	ContractPartInclusion = "contract_part_inclusion",
	ExportFreightCap = "export_freight_cap",
	FreightAccessorial = "freight_accessorial",
	FreightException = "freight_exception",
	FreightCap = "freight_cap",
	FreightMin = "freight_min",
	FreightRates = "freight_rates",
	FuelSurcharge = "fuel_surcharge",
	LaborPricing = "labor_pricing",
	LaborPricingHistory = "labor_pricing_history",
	LaborPricingList = "labor_pricing_list",
	LinePricing = "line_pricing",
	OverrideException = "override_exception",
	PermanentPricingUpdate = "permanent_pricing_update",
	PermanentPricingUpdateList = "permanent_pricing_update_list",
	PriceMatrix = "price_matrix",
	PriceMatrixItems = "price_matrix_items",
	Pricing = "pricing",
	PricingCustomers = "pricing_customers",
	Rates = "rates",
	RemoveCustomer = "remove_customer",
	TaxEstimate = "tax_estimate",
	UpdateFreightCap = "update_freight_cap",
}

export const idIsLineItemTypeId = (id: string): id is LineItemType => {
	return Object.values(LineItemType).includes(id as LineItemType)
}

export interface TaxLinePricingLine {
	quantity: number
	partNumber: string
	tax: number
	subTotal: number
	total: number
	unitPrice: number
}

export interface TaxLinePricing {
	nonTaxableCustomer: boolean
	totalTax: number
	subTotalAmount: number
	totalAmount: number
	lines: TaxLinePricingLine[]
}

export interface TaxPricingPost {
	customerId?: string | null
	customerNumber?: string | null
	lines: GenericTaxLineItem[]
	shipTo: {
		line1?: string | null
		line2: string | null
		city: string | null
		region: string | null
		postalCode: string | null
		country?: string | null
	}
}

export interface LinePricingLine {
	contractCovered: boolean
	lineTypeId: number
	netPrice: number
	partId: number
	/** Percentage, not a decimal. For ex, 7 not 0.07. */
	taxRate: number
	unitPrice: number
	unitTax: number
	overridePermission: {
		id: string
		description: APPLICATION
	} | null
}

export interface LinePricing {
	billToId: number
	customerId: number
	lines: LinePricingLine[]
}

export interface LinePricingPost {
	billToId: string | null
	customerId: string
	lines: {
		lineTypeId: string
		partId: string | null
	}[]
}

export interface PricingMatrix {
	id: string
	description: string
}
export interface TaxEstimatePost {
	customerId: string | null
	lines?: LineTaxEstimate[]
}

export interface LineTaxEstimate {
	part: any
	part_number: string | null
	amount: number
	quantity: number
	partNumber: number
}

export interface TaxCalculation {
	customerId: string
	calculateTax: boolean
	address?: string
	isGuest?: boolean
}

export interface TaxEstimateResponse {
	nonTaxableCustomer: boolean
	totalTax: number
	subTotalAmount: number
	totalAmount: number
	lines: {
		part_number: string
		tax: number
		subTotal: number
		total: number
	}[]
}

export type PricingMatricesQueryParams = SearchQueryParams

export interface PricingMatrixPost {
	description: string
}

export interface PricingMatrixPatch {
	description: string
}

export interface PricingMatrixItem {
	id: string
	part: string
	discountRate: string | null
	exceptionPrice: string | null
	locked: boolean
	partDescription: string
}

export interface PricingMatrixItemsQueryParams extends SearchQueryParams {
	matrix: string | null
}

export interface PricingMatrixItemPost {
	discountRate: number | null
	exceptionPrice: number | null
	locked: boolean
	partId: string
	pricingMatrixId: string
}

export interface AddCustomerToPricingMatrixPost {
	id: string // The ID of the matrix.
	customerId: string
}

export interface RemoveCustomerFromPricingMatrixPatch {
	customerId: string
}

export enum LaborRateTypeId {
	Labor = "1",
	FlatRate = "2",
}

export interface LaborPricingListItem {
	id: string
	type: string
	description: string | null
	territory: {
		id: string
		code: string
		description: string
	} | null
	zone: number | null
	currentRate: number | null
	expiresAfter: string | null
	customerCount: number | null
}

export type LaborPricingListQueryParams = {
	includeExpired: boolean | null
	laborRateType: LaborRateTypeId | null
	customer: string | null
	territory: string | null
	includeUnused: boolean | null
} & OrderingQueryParams &
	SearchQueryParams

export interface LaborPricingDetails {
	id: string
	type: string
	description: string | null
	territory: {
		id: string
		code: string
		description: string
	} | null
	zone: number | null
	priceHistory: {
		id: string
		startsOn: string
		endsAfter: string | null
		price: string
		modifiedBy: string | null
		modifiedDate: string
		createdBy: string | null
		createdDate: string
	}[]
	customers: {
		address1: string
		address2: string
		city: string
		customerId: string
		customerNumber: string
		membershipEndsAfter: string | null
		membershipId: string
		membershipStartsOn: string
		name: string
		state: string
		zip: string
	}[]
}

export type LaborPricingDetailsQueryParams = {
	includeInactiveCustomers: boolean | null
}

export type CreateLaborPricingPost =
	| {
			// For creating a LaborPricing at the same time.
			price: number
			startsOn: string
			endsAfter: string
			typeId: LaborRateTypeId
			territory: string | null
			zone: number | null
			description: string | null
	  }
	| {
			// Creating just LaborPricingPriceSchedule
			price: number
			startsOn: string
			endsAfter: string | null
			laborPricingId: string | null
	  }

export type UpdateLaborPricingPatch =
	| {
			// Updating a LaborPricingPriceSchedule
			newStartDate: string | undefined
			newEndDate: string | undefined
			rateId: string
	  }
	| {
			// Updating a LaborPricing
			laborPricingId: string
			description?: string
			zone?: number | null
	  }

export interface AddLaborPricingCustomersPost {
	billToId: string | null
	shipToId: string | null
	membershipStartsOn: string
	membershipEndsAfter: string | null
}

export interface UpdateLaborPricingCustomersPatch {
	memberships: string[]
	newEndDate: string | null
}

export type LaborPricingCustomerHistory = {
	membershipId: string
	laborPricingId: string
	description: string | null
	startsOn: string
	endsAfter: string | null
	territory: {
		id: string
		code: string
		description: string
	} | null
	zone: number | null
	prices: {
		id: string
		startsOn: string
		endsAfter: string | null
		price: number
	}[]
}[]

export type LaborPricingHistoryQueryParams = {
	type: LaborRateTypeId | null
}

export interface AccessorialPrice {
	id: number
	createdOn: string
	description: string
	endDate: string
	freeFreightExcluded: boolean
	lineItemTypeId: number
	modifiedOn: string
	rate: number
	startDate: string
	userCreated: string
	userLastModified: string
}

export type AccessorialPricesQueryParams = {
	active: true | null
}

export interface CreateAccessorialPricePost {
	lineItemTypeId: LineItemType
	rate: number
	startDate: string
	endDate: string
	freeFreightExcluded: boolean
}

export type UpdateAccessorialPricePatch = Partial<{
	lineItemTypeId: LineItemType
	rate: number
	startDate: string
	endDate: string
	freeFreightExcluded: boolean
}>

export interface FuelSurcharge {
	id: number
	createdOn: string
	dieselRate: number
	endDate: string
	gasolineRate: number
	startDate: string
	userCreated: string
}

export type FuelSurchargeQueryParams = {
	active: true | null
}

export type FreightCapTabQueryParams = {
	active: true | null
}

export interface CreateFuelSurchargePost {
	dieselRate: number
	endDate: string
	gasolineRate: number
	startDate: string
}

export interface FreightCap {
	freightCapType: number
	threshold: number
	thresholdActive: boolean
	freightType: string
}

export interface UpdateFreightCapPost {
	freightCapType: number
	threshold: number
	thresholdActive: boolean
	userId: number
}

export interface ExportFreightCapPost {
	userId: number
}

export type FreightCapQueryParams = {
	active: true | null
}

export type FreightMin = {
	id: number // Not unique! This object is a combination of customer freight mins and default freight mins.
	amount: number
	createdOn: string
	endDate: string
	modifiedOn: string
	startDate: string
	userCreated: string
	userLastModified: string
} & (
	| {
			customerId: number
			customerName: string
			customerNumber: string
			freightClassId: null
			freightClassName: null
	  }
	| {
			customerId: null
			customerName: null
			customerNumber: null
			freightClassId: number
			freightClassName: string
	  }
)

export type FreightMinsQueryParams = {
	active: true | null
	defaultsOnly: true | null
	customersOnly: true | null
	customer: string | null
}

export type CreateFreightMinPost = {
	amount: number
	startDate: string
	endDate: string
} & (
	| {
			customerId: string
			freightClassId?: never
	  }
	| {
			freightClassId: FreightClassId
			customerId?: never
	  }
)

export type UpdateFreightMinPatch = {
	rateId: string | number
	amount: number | undefined
	startDate: string | undefined
	endDate: string | undefined
} & (
	| {
			customerId: string
			freightClassId?: never
	  }
	| {
			freightClassId: FreightClassId
			customerId?: never
	  }
)

export interface FreightException {
	id: number
	allOrders: boolean
	createdOn: string
	customerName: string
	customerNumber: string
	endDate: string
	modifiedOn: string
	orderCountPerMonth: number | null
	startDate: string
	userCreated: string
	userLastModified: string
}

export type FreightExceptionsQueryParams = {
	active: true | null
	customer: string | null
}

export interface CreateFreightExceptionPost {
	customerId: string
	startDate: string
	endDate: string
	orderCountPerMonth: number | null
	allOrders: boolean
}

export interface UpdateFreightExceptionPatch {
	startDate: string
	endDate: string
	orderCountPerMonth: number | null
	allOrders: boolean
}

export interface FreightRate {
	id: string // Not unique!
	baseAmount: number
	createdOn: string
	customerId: string | null
	customerName: string | null
	customerNumber: string | null
	endDate: string
	freightProductGroupId: string | null
	freightProductGroupName: string | null
	modifiedOn: string
	rate: number
	startDate: string
	userCreated: string
	userLastModified: string
}

export type FreightRatesQueryParams = {
	active: true | null
	defaults: boolean | null
	customers: boolean | null
	products: boolean | null
	customerId: string | null
}

export interface CreateFreightRatePost {
	rate: number
	baseAmount: number
	startDate: string
	endDate: string
	customerId: string | null
	freightProductGroupId: string | null
}

export type UpdateFreightRatePatch = {
	rateId: string
	// These can't be changed but are required to tell which rate model ID goes to.
	customerId: string | null
	freightProductGroupId: string | null
} & Partial<{
	rate: number
	baseAmount: number
	startDate: string
	endDate: string
}>

export enum FreightGroupId {
	Conventional = "1",
	Ultra = "2",
	Other = "3",
}

export enum FreightTypeId {
	Ground = "1",
	PriorityOvernight = "2",
}

export enum PartStatus {
	A = "A",
	B = "B",
	C = "C",
	D = "D",
}

export enum FreightGroupDescription {
	Conventional = "Conventional",
	Ultra = "Ultra",
	Other = "Other",
}

export enum FreightTypeDescription {
	Ground = "Ground",
	PriorityOvernight = "Priority Overnight",
}

export interface LineItemPriceOverride {
	id: string
	approvedOn: string | null
	approverComments: string | null
	approverName: string | null
	currentRate: number
	customerName: string
	customerNumber: string
	deniedOn: string | null
	dispatchId: string | null
	dispatchNumber: string | null
	lineApplication: APPLICATION | null
	lineDescription: string | null
	lineTypeDescription: string | null
	partOrderId: string | null
	partOrderOrderId: string | null
	pendingApproval: boolean
	reasonComment: string | null
	reasonDescription: string | null
	requestedOn: string
	requestedRate: number
	requestorName: string
}

export type LineItemPriceOverridesQueryParams = {
	unhandled: true | null
	territories: string[] | null
}
export interface SubmitOverrideDecisionPost {
	id: string
	approved: boolean
	comments: string | null
}

export enum PermanentPriceAction {
	Create = "Create",
	Submit = "Submit",
	Approve = "Approve",
	Deny = "Deny",
	RequestMoreInfo = "Request More Info",
	Modify = "Modify",
	Cancel = "Cancel",
}

export enum PermanentPriceStatus {
	Open = "Open",
	Submitted = "Submitted",
	FollowUpNeeded = "Follow-up Needed",
	Approved = "Approved",
	Denied = "Denied",
	Closed = "Closed",
	Canceled = "Canceled",
}

export interface PricingAdjustmentRequestListItem {
	id: string
	status: PermanentPriceStatus
	assignedTo: string
	lastSubmissionDate: string | null
	description: string
}

export type PricingAdjustmentRequestsQueryParams = {
	status: string | null
	assignedTo: string | null
}

export interface PricingAdjustmentRequest {
	id: string
	status: PermanentPriceStatus
	assignedTo: string
	lastSubmissionDate: string | null
	description: string
	notes: string | null
	partVolume: string | null
	laborVolume: string | null
	chemicalVolume: string | null
	actions: PricingAdjustmentRequestAction[]
	customers: PricingAdjustmentRequestCustomer[]
	freight: PricingAdjustmentRequestFreight
}

export interface PricingAdjustmentRequestAction {
	comment: string | null
	createdBy: string
	action: PermanentPriceAction
	actionDate: string
}

export interface PricingAdjustmentRequestCustomer {
	customerId: string
	customerName: string
	customerNumber: string
}

export interface PricingAdjustmentRequestFreight {
	ordersPerMonth: number | null
	currentOrdersPerMonth: number | null
	allOrdersFree: boolean | null
	currentAllOrdersFree: boolean | null
	minOrderAmount: number | null
	currentMinOrderAmount: string | null
	chemicalFuelSurcharge: boolean | null
	currentChemicalFuelSurcharge: boolean | null
	serviceFuelSurcharge: boolean | null
	currentServiceFuelSurcharge: boolean | null
	fuelSurchargeFreightException: boolean | null
	currentFuelSurchargeFreightException: boolean | null
	accessorials: {
		lineTypeId: LineItemType
		price: number | null
		currentPrice: number | null // What is this? The default price of the accessorial?
		description: string
	}[]
}

export interface CreatePricingAdjustmentRequestPost {
	billToId: string | null
	shipToId: string | null
	description: string
}

export type UpdatePricingAdjustmentRequestPatch = Partial<{
	notes: string | null
	description: string
	addShipToId: string | null
	addBillToId: string | null
	removeCustomerId: string | null
	removeAllCustomers: boolean | null
	ordersPerMonth: number | null
	allOrdersFree: boolean | null
	minOrderAmount: number | null
	chemicalFuelSurcharge: boolean | null
	serviceFuelSurcharge: boolean | null
	accessorials: {
		lineTypeId: LineItemType
		price: number
	}[]
	action: "submit" | "approve" | "deny" | "more-info" | "cancel"
	actionComments: string | null
}>

export interface CustomerContractParts {
	id: string
	effectiveDate: string
	equipmentModel: string | null
	equipmentSerial: string | null
	equipmentVintage: string | null
	expirationDate: string
	isActive: boolean
	number: string | null
	type: string | null
	parts: {
		inclusionId: string
		id: string
		partNumber: string
		description: string
	}[]
}

export interface CustomerContractPartsQueryParams {
	customer: string | null
	contractType: string | null
}

export interface CreateContractPartPost {
	contract: string
	part: string
}

export interface DeleteContractPart {
	contract: string | undefined
	inclusion: string | undefined
}
