import { z } from "zod"

import {
	OrderingQueryParams,
	OrderingQueryParamsSchema,
	SearchQueryParams,
	SearchQueryParamsSchema,
} from "../../request-hooks"
import { PartMinimal, PartMinimalSchema } from "../common"
import { EquipmentVintageLookup } from "../lookups"
import { UserMinimal } from "../security"
import { InventoryPart } from "../inventory"

export enum PointOfSaleUrlPaths {
	BrandCard = "brand_card",
	BrandCardImage = "brand_card_image",
	BrandCardOrder = "brand_card_order",
	CarouselImage = "carousel_image",
	CarouselImageOrder = "carousel_order",
	CarouselImageUpload = "carousel_image_upload",
	Catalog = "catalog",
	Categories = "categories",
	CustomerParts = "customer-parts",
	Details = "details",
	Issue = "issue",
	Manufacturers = "manufacturers",
	Order = "order",
	Part = "part",
	PartDetail = "part_detail",
	PartRelationship = "part_relationship",
	Parts = "parts",
	PointOfSale = "point_of_sale",
	Reason = "reason",
	Submission = "submission",
	Summary = "summary",
	Systems = "systems",
	User = "user",
	UserBrandCard = "user_brand_card",
}

export enum VariantPartOptions {
	Size = "Size",
	Color = "Color",
	CaseSize = "Case Size",
}

/** AKA `OnlinePart` in ncsportal. TODO: change to `OnlinePart` once the switch to `CustomerPart` has been merged. */
export interface PosPart {
	id: number
	categories: PartCategory[]
	description: string
	flatShippingRate: string | null
	imageLastModified: string | null
	imageUrl: string | null
	isActive: boolean
	isShippedFlatRate: boolean
	lastModified: string | null
	modifiedBy: UserMinimal | null
	onlinePartNumber: string | null // Nullable because of parent parts
	part: PartMinimal | null // Nullable because of parent parts
	price: string | null // Nullable because of parent parts
	shippingLeadDays: number | null
	title: string
	vintages: EquipmentVintageLookup[]
	isParent: boolean
	isChild: boolean
	featuredItem: boolean
	clearanceItem: boolean
}

export interface PosPartQueryParams extends SearchQueryParams, OrderingQueryParams {
	category: string | null
	part: string | null // ID of the `InventoryPart` behind the `PosPart`?
	active: boolean | null
	featured: boolean | null
	clearance: boolean | null
	parent: boolean | null
	child: boolean | null
}

export interface PosPartPost {
	flatShippingRate: string | null
	isShippedFlatRate: boolean
	isActive: boolean
	onlinePartNumber: string | null
	partId: string | null
	description: string | null
	price: number | null
	title: string | null
	isParent: boolean
	isChild: boolean
	parentPartId: string | null
	featuredItem: boolean
	clearanceItem: boolean
	file: File | null // Product image file

	// These should be an array of PKs joined with ','
	categories: string | null // PartCategory IDs
	vintages: string | null // EquipmentVintage IDs
}

export interface PosPartPatch extends Omit<PosPartPost, "categories" | "vintages" | "file"> {
	// Unlike the POST call, the PATCH call looks for actual arrays of objects with ID.
	categories: { _id: string }[] | null
	vintages: { _id: string }[] | null
}

/** An `OnlinePart` that's returned from the point of sale `customer-parts` endpoint. */
export const CustomerPartSchema = z.object({
	id: z.string(),
	description: z.string().nullable(),
	flatShippingRate: z.string().nullable(),
	imageUrl: z.string().nullable(),
	childImage: z.string().nullable(),
	isActive: z.boolean(),
	isShippedFlatRate: z.boolean(),
	/** Like price, except it includes any discount the customer may have. Use it,
	 * but note that this entirely depends on the customer, and it's the price at a
	 * certain point in time. Not fixed. */
	netPrice: z.number().nullable(),
	childNetPrice: z.number().nullable(),
	/** Can be the same as its Part's part number, but NOT always. */
	onlinePartNumber: z.string().nullable(),
	part: PartMinimalSchema.nullable(),
	price: z.string().nullable(),
	shippingLeadDays: z.number(),
	/** This is the best option for the name of the part. Use it. */
	title: z.string().nullable(),
	/** Is this is a parent part? */
	isParent: z.boolean(),
	/** Is this is a child part? */
	isChild: z.boolean(),
	variantCount: z.number(),
	brandName: z.string().nullable(),
	featuredItem: z.boolean(),
	clearanceItem: z.boolean(),
})
export type CustomerPart = z.infer<typeof CustomerPartSchema>

export const CustomerPartQueryParamsSchema = z
	.object({
		manufacturers: z.array(z.string()),
		systems: z.array(z.string()),
		categories: z.array(z.string()),
		price_Gte: z.string().nullable(),
		price_Lt: z.string().nullable(),
		featured: z.boolean().nullable(),
		clearance: z.boolean().nullable(),
		onlinePartId: z.array(z.string()), // Array! You can pass an array of IDs.
		chemicals: z.array(z.string()),
		show_children: z.boolean().nullable(),
	})
	.merge(SearchQueryParamsSchema)
	.merge(OrderingQueryParamsSchema)
export type CustomerPartQueryParams = z.infer<typeof CustomerPartQueryParamsSchema>

/**
 * An `CustomerPart` where we're sure it's not a parent. The same as an `CustomerPart`,
 * except a few fields become not nullable.
 */
export const NonParentCustomerPartSchema = CustomerPartSchema.omit({
	netPrice: true,
	price: true,
	part: true,
}).extend({
	netPrice: z.number(),
	price: z.string(),
	part: PartMinimalSchema,
})
export type NonParentCustomerPart = z.infer<typeof NonParentCustomerPartSchema>

export const isNonParentCustomerPart = (part: CustomerPart): part is NonParentCustomerPart => {
	if (
		part.isParent === false &&
		(part.netPrice == null || part.price == null || part.part == null)
	) {
		console.warn(
			`OnlinePart ID ${part.id} has a netPrice, price, or part that is null, but we're asserting that they are not null because isParent is false! This will probably cause issues!`
		)
	}

	return part.isParent === false
}

export interface OnlinePartRelations {
	parent: {
		// These will not be present if part has no parent.
		id?: string
		title?: string
	}
	child: {
		id: string
		title: string
	}[]
}

export interface OnlinePartRelationsQueryParams {
	onlinePart: string
}

export interface ChemicalOnlinePartQueryParams {
	isChemical: boolean
}

export interface OnlinePartDetails {
	brands: {
		brandId: string
		brandName: string
	}[]
	categories: {
		categoryId: string
		categoryName: string
	}[]
	systems: {
		systemId: string
		systemName: string
	}[]
	relatedParts: {
		id: string
		title: string
		imageUrl: string
		onlinePartNumber: string
	}[]
	available: boolean
	parentId: number | null
	children: OnlineChildPart[]
	options: OnlinePartOptions
	part?: InventoryPart
}

export interface OnlineChildPart {
	imageUrl: string | undefined
	onlinePartId: number
	title: string
	description: string
	price: number
	netPrice: number
	isActive: boolean
	options: OptionVariant[]
}

export interface OnlinePartOptions {
	size: OptionVariant[]
	color: OptionVariant[]
	caseSize: OptionVariant[]
}

export interface OptionVariant {
	option: string
	variant: string
}

export interface CustomerManufacturer {
	id: number
	code: string | null
	name: string | null
}

export interface PartCategory {
	id: number
	name: string | null
	isActive: boolean
}

/** For now, a `PartChemical` is really just a `PartCategory`. */
export interface PartChemical extends PartCategory {}

export interface PartCategoryPost {
	name: string
	isActive: boolean
}

export interface PartCategoryPatch {
	name: string | null
	isActive: boolean
}

export type CustomerSystem = {
	id: number
	modelName: string
	manufacturer: CustomerManufacturer
}

export enum ShippingService {
	// The two shipping methods for parts.
	FedExGround = "FEDEX_GROUND",
	PriorityOvernight = "PRIORITY_OVERNIGHT",
	// And one for chemicals.
	LTL = "LTL",
	// And I think none of these are used anymore?
	FedEx2Day = "FEDEX_2_DAY",
	NCS_SCHEDULED_ROUTE = "NCS_SCHEDULED_ROUTE",
	StandardOvernight = "STANDARD_OVERNIGHT",
	TBD = "TBD",
	WWL = "WWL",
}

export const ShippitItemSchema = z.object({
	/** OnlinePart ID */
	id: z.string(),
	contractCovered: z.boolean(),
	description: z.string().nullable(),
	imageUrl: z.string().nullable(),
	/** "Offline" part ID associated with the OnlinePart */
	partId: z.string(),
	/** This is the part number of the offline part, not the online part number. */
	partNumber: z.string().nullable(),
	price: z.number(),
	quantity: z.number(),
	taxRate: z.number().nullable(), // Null if canadian.
	title: z.string().nullable(),
	unitTax: z.number().nullable(),
	unitOfMeasure: z.string().nullable(),
})
export type ShippitItem = z.infer<typeof ShippitItemSchema>

export const ShippitAccessorialSchema = z.object({
	freeFreightExcluded: z.boolean(),
	lineItemDescription: z.string(),
	lineItemTypeId: z.number(),
	rate: z.number(),
})
export type ShippitAccessorial = z.infer<typeof ShippitAccessorialSchema>

export interface ShippitShipment {
	items: ShippitItem[]
	service: {
		cost: number | null // Null if canadian (but probably not actually?)
		estimatedDelivery: string
		label: string // TBD if canadian
		name: ShippingService // TBD if canadian
	}[]
}

export interface ShippitCustomerAddress {
	id: number // customer ID
	address_1: string
	address_2: string
	city: string
	name: string
	postalcode: string
	state: string
}

export interface ShippitResponse {
	accessorials: ShippitAccessorial[]
	shipments: Record<string, ShippitShipment>
}

export interface ProductCatalog {
	id: number
	description: string
}

export interface ProductCatalogPost {
	description: string
}

export interface ProductCatalogPatch {
	catalogId: number
	description: string
}

export interface ProductCatalogDetails {
	catalogId: string
	description: string
	parts: {
		partNumber: string
		description: string
		onlinePartId: string
	}[]
	users: {
		userId: number
		userName: string
	}[]
}

export interface ProductCatalogUserPOst {
	userId: number
	catalogId: number
}

export interface ProductCatalogUserDelete {
	userId: number
	catalogId: number
}

export interface ProductCatalogPartPost {
	onlinePartId: number
	catalogId: number
}

export interface ProductCatalogPartDelete {
	onlinePartId: number
	catalogId: number
}

export interface BrandCard {
	id: string
	name: string
	taglineText: string
	buttonDestinationUrl: string
	buttonDestinationOpensNewWindow: boolean
	logoImageUrl: string
	bannerImageUrl: string
	isActive: boolean
	modifiedOn: string | null
	modifiedBy: string | null
	modifiedByName: string | null
	createdOn: string
	createdBy: string
	createdByName: string
	presentationOrder: number
}

export interface BrandCardQueryParams {
	card: string | null // Get single brand card by ID.
	user: string | null // Get brand cards for specific user.
}

export interface BrandCardPost {
	name: string
	taglineText: string
	buttonDestinationUrl: string
	buttonDestinationOpensNewWindow: boolean
	logoImageUrl: string
	presentationOrder: number
	isActive: boolean
}

export interface BrandCardPatch {
	name: string
	taglineText: string
	buttonDestinationUrl: string
	buttonDestinationOpensNewWindow: boolean
	logoImageUrl: string
	presentationOrder: number
	isActive: boolean
}

export interface BrandCardOrderPost {
	cards: string[] // array of all the card IDs in their desired order.
}

export interface CarouselImage {
	id: string
	name: string
	text: string
	destinationUrl: string
	destinationOpensNewWindow: boolean
	desktopImageUrl: string
	mobileImageUrl: string | null
	tabletImageUrl: string | null
	tvImageUrl: string | null
	isActive: boolean
	modifiedOn: string | null
	modifiedBy: string | null
	modifiedByName: string | null
	createdOn: string
	createdBy: string
	createdByName: string
	presentationOrder: number
}

export interface CarouselImageQueryParams {
	image: string | null
	user: string | null
}

export interface CarouselImagePost {
	name: string
	text: string
	destinationUrl: string
	destinationOpensNewWindow: boolean
	desktopImageUrl: string
	mobileImageUrl: string
	tabletImageUrl: string
	tvImageUrl: string
	presentationOrder: number
	isActive: boolean
}

export interface CarouselImagePatch {
	name: string
	text: string
	destinationUrl: string
	destinationOpensNewWindow: boolean
	desktopImageUrl: string
	mobileImageUrl: string
	tabletImageUrl: string
	tvImageUrl: string
	presentationOrder: number
	isActive: boolean
}

export interface CarouselImageOrderPost {
	images: string[]
}

export interface UserBrandCardsPost {
	user: string
	cards: string[]
}

export interface IssueReason {
	id: string
	description: string
	requiresAuth: boolean
}

export interface CreateCustomerIssuePost {
	user: string | null // User ID
	contactEmail: string
	description: string
	reason: string
}

export interface PartDetail {
	id: string
	partNumber: string
	description: string
}

export interface Part {
	id: string
	description: string | null
	imageUrl: string | null
	isActive: boolean
	price: string
	part: PartDetail
	onlinePartNumber: string | null
	title: string | null
	flatShippingRate: string | null
	isParent: boolean
	isShippedFlatRate: boolean
	shippingLeadDays: number
	featuredItem: boolean
	clearanceItem: boolean
	isChild: boolean
	variantCount: number | null
	brandName: string | null
	netPrice: number
	childNetPrice: number | null
}

export interface CartItem {
	part: Part
	quantity: number
}

/**
 * These are the categories that we're picking from the full list of categories and
 * deeming them to also be a new faux taxonomy, "Chemicals".
 */
export enum ChemicalCategoryId {
	Lustra = "42",
	CleanTouch = "43",
}
