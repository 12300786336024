import { FC, useEffect, useMemo, useState } from "react"

import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import { BulkImportMetaModal, BulkImportPeopleAlignmentsModal } from "~/components"

import {
	APPLICATION,
	CustomerMin,
	CustomerMinQueryParams,
	defaultCustomerMinQueryParams,
	useCustomerMins,
	useExportCustomerMins,
	useUserCanUse,
} from "@ncs/ncs-api"
import { Typify } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	CustomerQueryFilter,
	EmptyValueDash,
	EnterpriseQueryFilter,
	Icon,
	IsFstActiveQueryFilter,
	OrganizationQueryFilter,
	RecentItems,
	SearchQueryFilter,
	Table,
	TerritoryQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type CustomerManagementUrlState = Typify<CustomerMinQueryParams>

export const CustomerManagement: FC = () => {
	const history = useHistory()
	const [selectedCustomer, setSelectedCustomer] = useState<CustomerMin | null>(null)
	const [filterParams, { setUrlState }] = useUrlState<CustomerManagementUrlState>(
		defaultCustomerMinQueryParams
	)
	const [pagination, setPagination] = usePaginationUrlState()
	const canBulkUploadMetaData = useUserCanUse(APPLICATION.CustomerManagementBulkUploads)
	const canExportData = useUserCanUse(APPLICATION.CustomerManagementExport)

	const params = useMemo(() => {
		return { ...filterParams, page: pagination.page, pageSize: pagination.pageSize }
	}, [filterParams, pagination.page, pagination.pageSize])
	const [showMetaImportModal, setShowMetaImportModal] = useState(false)
	const [showPeopleImportModal, setShowPeopleImportModal] = useState(false)

	const dataExport = useExportCustomerMins(filterParams)
	const customersQuery = useCustomerMins({ manualPagination: true, params })

	const moreFiltersShouldStartOpen = useMemo(() => {
		const keys: (keyof typeof filterParams)[] = [
			"organization",
			"enterprise",
			"territory",
			"customer",
			"service",
		]
		return keys.some((key) => filterParams[key] != null)
	}, [filterParams])

	useEffect(() => {
		if (selectedCustomer) {
			history.push({
				pathname: `/customer-management/${selectedCustomer.id}`,
				state: { customerQueryParams: params },
			})
		}
	}, [history, params, selectedCustomer])

	return (
		<Card
			headingIcon="users"
			heading="Customer Management"
			renderRight={() => {
				return (
					canBulkUploadMetaData && (
						<Box
							display="flex"
							justifyContent="flex-end"
							alignItems="center"
							columnGap={1}
						>
							<Button
								icon="file-spreadsheet"
								onClick={() => setShowPeopleImportModal(true)}
							>
								Bulk update people alignments
							</Button>
							<Button
								icon="file-spreadsheet"
								onClick={() => setShowMetaImportModal(true)}
							>
								Bulk update customer information
							</Button>
						</Box>
					)
				)
			}}
		>
			<Box mb={3} mt={-2}>
				<RecentItems
					item={selectedCustomer}
					itemLabelAccessor={(item) => `(${item.customerNumber}) ${item.name}`}
					onSelect={(item) => setSelectedCustomer(item)}
				/>
			</Box>

			<Table
				columns={columns}
				query={customersQuery}
				queryParamState={filterParams}
				setQueryParamState={setUrlState}
				dataExport={canExportData ? dataExport : undefined}
				pinnedQueryFilters={[SearchQueryFilter]}
				toggledQueryFilters={[
					TerritoryQueryFilter,
					EnterpriseQueryFilter,
					OrganizationQueryFilter,
					CustomerQueryFilter,
					IsFstActiveQueryFilter,
				]}
				disableAllSorting
				onRowClick={({ original }) => setSelectedCustomer(original)}
				loadingText="Loading customers..."
				showToggledFiltersByDefault={moreFiltersShouldStartOpen}
				pagination={pagination}
				setPagination={setPagination}
			/>
			{/* 
				If we are asked to create more of these bulk upload mechanisms on this page, the idea is to push 
				these modals down another layer, so these two modals would be replaced with one single one that would 
				allow the user to pick from multiple upload functions. We should not end up with a large number of modals at the end of this file.
			*/}
			<BulkImportMetaModal
				isOpen={showMetaImportModal}
				onClose={() => setShowMetaImportModal(false)}
				title="Import Customer Metadata"
			/>
			<BulkImportPeopleAlignmentsModal
				isOpen={showPeopleImportModal}
				onClose={() => setShowPeopleImportModal(false)}
				title="Import People Alignments"
			/>
		</Card>
	)
}

const columns: Column<CustomerMin>[] = [
	{
		Header: "Customer #",
		accessor: "customerNumber",
	},
	{
		Header: "Customer Name",
		accessor: "name",
	},
	{
		Header: "Address",
		accessor: "address2",
	},
	{
		Header: "City",
		accessor: "city",
	},
	{
		Header: "State",
		accessor: "state",
	},
	{
		Header: "Postal code",
		accessor: "postalcode",
	},
	{
		Header: "Active?",
		accessor: "isActive",
		Cell: ({ row: { original } }: Cell<CustomerMin>) => {
			return original.isFstActive ?
					<div>
						<Icon icon="check" color="gray" /> Active
					</div>
				:	<EmptyValueDash />
		},
	},
]
